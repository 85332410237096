<template>
    <page-title
        title="社員編集"
        icon="bi-person-fill"
    >
        <router-link :to="{name: 'EmployeeList'}" class="btn btn-outline-primary">
            <i class="bi bi-reply"></i> 社員一覧へ
        </router-link>
    </page-title>

    <section class="section">
        <form>
            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label>氏名</label>
                    <input type="text" class="form-control" required value="片岡高志">
                </div>
                <div class="form-group col-md-3">
                    <label>略称(一文字)</label>
                    <input type="text" class="form-control" value="片">
                </div>
                <div class="form-group col-md-6 align-self-end pb-2">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked>
                        <label class="form-check-label" for="flexSwitchCheckDefault"><i class="bi bi-camera-fill"></i></label>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>ログインID</label>
                <input type="text" class="form-control" required value="kataoka12345">
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>パスワード</label>
                <input type="password" class="form-control">
                <small class="text-muted">変更する場合のみ入力。半角英数字8文字以上</small>
            </div>
            <label>権限</label>
            <div class="form-group col-md mb-4">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" checked>
                    <label class="form-check-label" for="inlineCheckbox1">社員管理</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" checked>
                    <label class="form-check-label" for="inlineCheckbox2">応援カメラマン管理</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                    <label class="form-check-label" for="inlineCheckbox3">スケジュール管理</label>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-primary btn-lg">
                    <i class="bi bi-pencil-square"></i> 編集
                </button>
                <button type="button" class="btn btn-outline-danger btn-lg" @click="$refs.confirm_remove.show()">
                    <i class="bi bi-trash"></i> 削除...
                </button>
            </div>
        </form>
    </section>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog';

export default {
    name: 'EmployeeEdit',
    components: {
        PageTitle,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        remove() {
            this.$router.push({name: 'EmployeeList'});
            this.showMessage('削除しました');
        }
    }
}
</script>

<style scoped>

</style>
